import './Header.css';
import Logo from '../assets/media/fomofox-logo.png';
import tg from '../assets/media/tg.png';
import x from '../assets/media/x.png';

function Header() {
    return (
        <>
            <section id="header">
                <div className="container">
                <nav className="navbar navbar-expand-sm navbar-light">
                    <div className="container">
                        <a className="navbar-brand" href="/"> <img src={Logo} alt="" /> </a>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mx-auto">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="#banner">Home</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="#presale">Presale</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" href="#about">About</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#fomonomics">Fomonomics</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#nft">NFT</a>
                                </li>
                            </ul>

                            <ul className="navbar-nav social ms-auto d-flex flex-row">  
                            
                                <li className="nav-item me-2">  
                                    <a className="nav-link" href="https://twitter.com/fomofox_meme" target='_blank'><img src={x} alt="X" /></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://t.me/fomofox_portal" target='_blank'><img src={tg} alt="Telegram" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                </div>

            </section>
        </>
    )
}

export default Header;