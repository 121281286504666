import './LandingPage.css';
import { useState, useEffect } from "react";
import Sandfox from '../assets/media/sandfox-main.png';
import AboutFomo from '../assets/media/about-fomo.png';
import FomonomicsImg from '../assets/media/fomonomics.png';
import PresaleFomo from '../assets/media/fomo-preslae.png';
import Bunny from '../assets/media/bunny.png';
import Hunt from '../assets/media/hunt.png';
import * as solanaWeb3 from "@solana/web3.js";
import Logo1 from '../assets/media/logo-1.svg';
import Logo2 from '../assets/media/logo-2.svg';
import Logo3 from '../assets/media/logo-3.svg';
import Logo4 from '../assets/media/logo-4.svg';
import Logo5 from '../assets/media/logo-5.svg';
import Common from '../assets/media/common.png';
import Rare from '../assets/media/rare.png';
import Epic from '../assets/media/epic.png';
import Legendary from '../assets/media/legendary.png';
import Myth from '../assets/media/myth.png';


function LandingPage() {


    const [tokenCopied, setTokenCopied] = useState(false);
    const [presaleCopied, setPresaleCopied] = useState(false);

    const [currentRaise, setCurrentRaise] = useState("0");
    const fetchSolToUsdRate = async () => {
        // Placeholder for fetching SOL to USD rate
        // This should be replaced with an actual API call to a service like CoinGecko, Binance, etc.
        // For example, using CoinGecko API:
        const response = await fetch(
            "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd"
        );
        const data = await response.json();
        return data.solana.usd;
    };

    // Fetch SOL balance
    const fetchSolBalance = async () => {
        const connection = new solanaWeb3.Connection(
            "https://fluent-misty-theorem.solana-mainnet.quiknode.pro/b3f41d61c265546813db435f7e1345b0916ab353/"
        );
        const address = new solanaWeb3.PublicKey(
            "EoYuWuNHLGnbfdpDH5FGewNhSe5mn7WsbmjSZ9sFGyF7"
        );

        try {
            const balanceInLamports = await connection.getBalance(address);
            const balanceInSol = balanceInLamports / solanaWeb3.LAMPORTS_PER_SOL;

            const solToUsdRate = await fetchSolToUsdRate();
            const balanceInUsd = (balanceInSol * solToUsdRate).toFixed(2);

            console.log("Balance in USD:", balanceInUsd);
            setCurrentRaise(balanceInUsd + " $");
        } catch (error) {
            console.error("Error fetching balance:", error);
            setCurrentRaise("Error");
        }
    };

    useEffect(() => {
        fetchSolBalance();
        const intervalId = setInterval(fetchSolBalance, 60000); // Fetch every 10 seconds

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const handleCopyToken = () => {
        const tokenAddressInput = document.getElementById('token-address');
        tokenAddressInput.select();
        document.execCommand('copy');
        setTokenCopied(true);

        // Reset the copied state after 2 seconds
        setTimeout(() => {
            setTokenCopied(false);
        }, 2000);
    };

    const handleCopyPresale = () => {
        const presaleAddressInput = document.getElementById('presale-address');
        presaleAddressInput.select();
        document.execCommand('copy');
        setPresaleCopied(true);

        // Reset the copied state after 2 seconds
        setTimeout(() => {
            setPresaleCopied(false);
        }, 2000);
    };

    setTimeout(() => {
        const preloader = document.querySelector('.preloader');
        preloader.classList.add('hidden'); // Hide the pre-loader after a delay
    }, 3000);


    const Countdown = () => {
        const [targetDate, setTargetDate] = useState(new Date(Date.UTC(2024, 4, 9, 15, 0, 0))); // April (0 indexed) 24 2024, 15:00 UTC

        const [days, setDays] = useState(0);
        const [hours, setHours] = useState(0);
        const [minutes, setMinutes] = useState(0);
        const [seconds, setSeconds] = useState(0);

        useEffect(() => {
            const intervalId = setInterval(() => {
                const now = new Date();
                const remainingTime = targetDate - now;

                const newDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
                const newHours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const newMinutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                const newSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

                setDays(newDays);
                setHours(newHours.toString().padStart(2, '0'));
                setMinutes(newMinutes.toString().padStart(2, '0'));
                setSeconds(newSeconds.toString().padStart(2, '0'));

                setDays(newDays.toString().padStart(2, '0')); // Two-digit padding
                setHours(newHours.toString().padStart(2, '0'));
                setMinutes(newMinutes.toString().padStart(2, '0'));
                setSeconds(newSeconds.toString().padStart(2, '0'));

                if (remainingTime <= 0) {
                    clearInterval(intervalId);
                    // Handle the timer reaching zero (optional)
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }, []);

        return (
            <div className="countdown-container">
                <div className="countdown-item days">
                    <span className="value">{days}</span>
                    <span className="label">Days</span>
                </div>
                <div className="countdown-item hours">
                    <span className="value">{hours}</span>
                    <span className="label">Hours</span>
                </div>
                <div className="countdown-item minutes">
                    <span className="value">{minutes}</span>
                    <span className="label">Minutes</span>
                </div>
                <div className="countdown-item seconds">
                    <span className="value">{seconds}</span>
                    <span className="label">Seconds</span>
                </div>
            </div>
        );
    };



    return (
        <>
            <div class="preloader">
                <div class="animation"></div>
            </div>

            <section id='banner'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="info-box">
                                <h1 className="title">Fomo ! Tibetan Sand Fox<br></br>Now On Solana !</h1>

                                <div className="banner-img">
                                    <img src={Sandfox} alt="SandFox" className='img img-fluid' />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>
                </div>
            </section>

            {/* <section id='separator'></section> */}

            {/* <section id="presale">
                <div className="container box">
                    <div className="row">
                        <div className="col-12 pb-4"><h2 className='text-center'>Secure Your Spot Before Blastoff !</h2></div>
                        <div className="col-md-6 d-flex align-items-center text-center justify-content-center pb-md-0 pb-2"><h2 className='mb-0'>Sale Ends in <i className="fas fa-chevron-right"></i> </h2></div>
                        <div className="col-md-6 countdown">
                            <Countdown />
                        </div>
                        <div className="data mt-3 fieldset d-flex justify-content-center justify-content-center align-items-center">
                            <div className="col-sm-4"><p className="mx-3 label">Current Raise</p></div>
                            <div className="col-sm-8 right"><h6 className='value'>{currentRaise}</h6></div>
                        </div>

                        <div className="data mt-3 fieldset input-group d-flex justify-content-center align-items-center">
                            <div className="col-sm-4"><p className='mx-3'>Presale Address</p></div>
                            <div className="col-sm-8 d-flex justify-content-center right pe-3">
                                <h6 className='token'>EoYuWuNHLGnbfdpDH5FGewNhSe5mn7WsbmjSZ9sFGyF7</h6>
                                <input id="presale-address" type="text" value="EoYuWuNHLGnbfdpDH5FGewNhSe5mn7WsbmjSZ9sFGyF7" readOnly style={{ position: 'absolute', left: '-9999px' }} />
                                <button className='ms-3 copy-btn' onClick={handleCopyPresale}><i className="fa fa-copy"></i><span className={presaleCopied ? 'presale-tooltip-show' : 'presale-tooltip-hide'}>Copied!</span></button>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">

                        <div className="col-md-8 col-sm-12 mt-4 mt-md-0 order-md-first d-flex align-items-center order-sm-last">
                            <ul>
                                <ul>
                                    <li>60% of $FOMO sold in Presale, Tokens are distributed 24 hours after the end of the sale.</li>
                                    <li>5% bonus pre-sale to 24 hours post-sale</li>
                                    <li>Pre-sale ends: May 9 at 15:00 UTC, DEX launch: May 10 at 15:00 UTC</li>
                                    <li>Send SOL from decentralized wallet (No CEX).</li>
                                    <li>There is no price! Proportional $FOMO to SOL contributed.</li>
                                    <li>Min Buy 0.2 SOL, No Max Buy</li>
                                    <li>*Lower deposits are used to buy and burn $FOMO.</li>
                                </ul>
                            </ul>

                        </div>
                        <div className="col-md-4 col-sm-12 order-md-last order-sm-first">
                            <img src={PresaleFomo} alt="Image Description" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section> */}

            <section id='separator'></section>

            <section id='about'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 order-md-first order-sm-last">
                            <img src={AboutFomo} alt="Image Description" className="img-fluid" />
                        </div>
                        <div className="col-md-8 col-sm-12 mt-4 mt-md-0 order-md-last order-sm-first">
                            <h2>Who Is Fomo?</h2>
                            <p className='desc'>Once upon a time, in Tibet, lived a unique fox named FOMO. Driven by a fear of missing out, he decided to journey to the moon. With courage, he traversed dense forests and rugged terrains. Overcoming challenges, he reached the moon, inspiring hope and resilience. FOMO's tale taught us to pursue our dreams. Now, retold, it sparks inspiration anew.</p>
                            <p className='desc text-danger'>🌘 During the project's journey to Moonland, we donate to WWF, Greenpeace, and IFAW.</p>
                        </div>
                    </div>
                </div>

            </section>

            <section id='separator'></section>

            <section id='fomonomics'>

                <div class="container">

                    <div class="row section-title"><h2>FOMONOMICS</h2></div>

                    <div class="row align-items-center text-center">

                        <div class="col-md-4 order-md-1 order-2">
                            <div class="text-box">
                                <p>Symbol</p>
                                <h3>$FOMO</h3>
                            </div>
                            <div class="text-box">
                                <p>Total | Supply</p>
                                <h3>500M</h3>
                            </div>

                        </div>

                        <div class="col-md-4 py-3 text-center order-md-2 order-1">
                            <img src={FomonomicsImg} alt="Image Description" class="img-fluid" />
                        </div>

                        <div class="col-md-4 order-md-3 order-3">

                            <div class="text-box">
                                <p>Presale/LP</p>
                                <h3>80%</h3>
                            </div>
                            <div class="text-box">
                                <p>Potential CEX Staking & Marketing</p>
                                <h3>20%</h3>
                            </div>
                        </div>


                    </div>

                    <div className="row mt-0 mt-md-4">
                        <div className="col-12">
                            <div class="text-box">
                                <div className="input-group d-flex justify-content-center align-items-center">
                                    <p className='me-4'>Token Address</p>
                                    <h3 className='token'>BxWuRuxQCUiLmRUT7cMJHMagnnZhdRqXXvB6nAmRWweA</h3>
                                    <input id="token-address" type="text" value="BxWuRuxQCUiLmRUT7cMJHMagnnZhdRqXXvB6nAmRWweA" readOnly style={{ position: 'absolute', left: '-9999px' }} />
                                    <button className='ms-3 copy-btn' onClick={handleCopyToken}><i className="fa fa-copy"></i><span className={tokenCopied ? 'tooltip-show' : 'tooltip-hide'}>Copied!</span></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <div class="d-flex flex-wrap justify-content-center gap-2">
                                <button type="button" class="btn btn-fomo">LP BURNED</button>
                                <button type="button" class="btn btn-fomo">OWNERSHIP REVOKED</button>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

            <section id='separator'></section>

            <section id="featured">
                <div className="container">
                    <div className="row">
                        <h2 className="section-title text-center pb-4">Featured In</h2>
                        <div className="col-12 d-flex flex-wrap justify-content-center justify-content-md-between">
                            <img src={Logo1} alt="" className="f-logo" />
                            <img src={Logo2} alt="" className="f-logo" />
                            <img src={Logo3} alt="" className="f-logo" />
                            <img src={Logo4} alt="" className="f-logo" />
                            <img src={Logo5} alt="" className="f-logo" />
                        </div>
                    </div>
                </div>
            </section>

            <section id='separator'></section>

            <section id="nft">
                <div className="container box">
                    <div class="row">
                        <div class="col d-flex justify-content-center align-items-center flex-wrap text-and-image">
                            <h2>NFT Staking</h2>
                        </div>
                    </div>

                    <div className="row mt-4">

                        <div className="col-12">
                            <ul>
                                <li><strong>NFT Minting</strong>: Randomly minted across 5 levels, totaling 3,000 NFTs.</li>
                                <li><strong>Rewards</strong>: Weekly automatic rewards for 52 weeks based on card level.</li>
                                <li><strong>Price</strong>: Initial price set at $50 for 500 NFTs.</li>
                                <li><strong>Revenue</strong>: Proceeds from minting sales used to back up the FOMO token.</li>
                                <li><strong>Distribution</strong>: Varies by level, with Common being the most abundant and Myth being the least.</li>
                            </ul>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-4 offset-md-2">
                        <img src={Common} className='img img-fluid p-2' alt="NFT" />
                            
                        </div>
                        <div className="col-md-4">
                        <img src={Rare} className='img img-fluid p-2' alt="NFT" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <img src={Epic} className='img img-fluid p-2' alt="NFT" />
                        </div>
                        <div className="col-md-4">
                            <img src={Legendary} className='img img-fluid p-2' alt="NFT" />
                        </div>
                        <div className="col-md-4">
                            <img src={Myth} className='img img-fluid p-2' alt="NFT" />
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default LandingPage;