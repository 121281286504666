import './Footer.css';

import FomoFooter from '../assets/media/footer-logo.png';
import tg from '../assets/media/tg.png';
import x from '../assets/media/x.png';

function Footer() {

    return (
        <>
            <footer className="bg-white py-3">
                <div className="container d-flex flex-wrap justify-content-between">
                    <div className="d-flex align-items-center">
                        <h2 className="me-3 mb-0">Fomo</h2>
                        <a href="https://twitter.com/fomofox_meme" target='_blank' className="social text-decoration-none">
                            <img src={x} alt="X" />
                        </a>
                        <a href="https://t.me/fomofox_portal" target='_blank' className="social text-decoration-none ms-2">
                            <img src={tg} alt="Telegram" />
                        </a>
                    </div>

                    <div className="text-end footer-logo">
                        <img src={FomoFooter} alt="Fomo" className="img-fluid" />
                    </div>
                </div>
                <div className="container copyright py-2">
                    <p className="text-center mb-0">© 2024 FOMO. All Rights Reserved.</p>
                </div>
            </footer>

        </>
    )

}

export default Footer;